<template>
    <div class="container">
        <h1 id="tableLabel">Carga de proyectos</h1>
        <div class="container">
            <select v-model="proyectoSeleccion" name="proyectoSeleccion" class="form-select">
                <option value="0" selected>Proyecto</option>
                <option v-for="proyecto of proyectos" v-bind:key="proyecto" :value=proyecto.id>
                    {{proyecto.nombre}}
                </option>
            </select>
            <input v-model="linkProyecto" type="text" class="form-control" placeholder="http://volumento.com" />
            <button type="button" class="btn btn-primary" v-on:click="Save()">Guardar</button>
        </div>
    </div>

    <!--toast-->

    <div class="toast-container position-fixed p-3 bottom-0 end-0">
        <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
    </div>
</template>
<script>
    import axios from 'axios'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "cargarProyecto",
        components: {
            Toast
        },
        data() {
            return {
                proyectos: null,
                toasts: [],
                proyectoSeleccion: null,
                proyecto: { "id": null, "linkProyecto": null },
                linkProyecto: null,
            }
        },
        methods: {
            getProyectos() {
                axios.get('/api/proyectos/0', { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {

                        this.proyectos = response.data;
                    })
                    .catch((error) => {
                        this.toasts.push({ "message": "No se pudo acceder a la informacion de proyectos, error:" + error.response.status, "delay": 5000 });
                        //alert(error);
                    });
            },
            Save() {
                this.proyecto.id = this.proyectoSeleccion;
                this.proyecto.linkProyecto = this.linkProyecto;

                axios.post('/api/proyectos/', this.proyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if(response.status ==200)
                        this.toasts.push({ "message": "Se actualizo la informacion de proyectos", "delay": 5000 });
                        //this.proyectos = response.data;
                    })
                    .catch((error) => {
                        this.toasts.push({ "message": "No se pudo acceder a la informacion de proyectos, error:" + error.response.status, "delay": 5000 });
                        //alert(error);
                    });
            }
        },
        mounted() {
            this.getProyectos();
        }
    }
</script>