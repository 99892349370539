<template>
    <div class="container">

        <h1 id="tableLabel">Proyectos</h1>

        <div class="row" style="margin-top:1rem">
            <div class="container">
                <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="proyecto of proyectos" v-bind:key="proyecto">
                            <td>{{ proyecto.nombre}}</td>
                            <td>
                                <template v-if="proyecto.linkProyecto!=null">
                                    <a :href="proyecto.linkProyecto" class="link-dark">
                                        <i class="bi bi-box-arrow-down"></i>
                                    </a>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!--toast-->
        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "DescargaProyecto",
        components: {
            Toast
        },
        data() {
            return {
                proyectos: null,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                toasts: [],
            }
        },
        methods: {
            getProyectos() {
                axios.get('/api/Proyectos/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.proyectos = response.data;
                    })
                    .catch((error) => {
                        this.toasts.push({ "message": "No se pudo acceder a la informacion de proyectos, error:" + error.response.status, "delay": 5000 });
                    });
            }
        },
        mounted() {
            this.getProyectos();
        }
    }
</script>