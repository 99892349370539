<template>
    <div class="container">
        <h1 id="tableLabel">Comercio</h1>

        <p v-if="!comercios"><em>Cargando...</em></p>

        <div class="container " v-if="comercios">
            <div class="row">
                <div>
                    <button @click="modal.show()" class="btn btn-outline-primary float-end" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" href="#exampleModal">
                        Modificar <i class="bi bi-file-earmark-excel"></i>
                    </button>
                </div>
            </div>

            <div class="row" style="margin-top:1rem">
                <div class="container">
                    <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                        <thead>
                            <tr>
                                <th>Unidad</th>
                                <th>Piso</th>
                                <th>Disponibilidad</th>
                                <th>Precio</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="comercio of comercios" v-bind:key="comercio">
                                <td>{{ comercio.unidad}}</td>
                                <td>{{ comercio.piso }}</td>

                                <!--disponibilidad-->
                                <template v-if="modifyComercio!=comercio.id">
                                    <td>{{ Disponibilidad[comercio.disponibilidad] }}</td>
                                </template>
                                <template v-else>
                                    <td>
                                        <select v-model="disponibleSelected" class="form-select" aria-label="">
                                            <option value=0>Disponible</option>
                                            <option value=1>Apartado</option>
                                            <option value=2>Vendido</option>
                                            <option value=3>Bloqueado</option>
                                        </select>
                                    </td>
                                </template>

                                <!--precio-->
                                <template v-if="modifyComercio!=comercio.id">
                                    <td>{{comercio.precio}}</td>
                                </template>
                                <template v-else>
                                    <input class="form-control" type="text" :placeholder=comercio.precio v-model="precioModel">
                                </template>

                                <!--boton Modificar/Guardad-->
                                <td>
                                    <template v-if="modifyComercio!=comercio.id">
                                        <button v-on:click="Modify(comercio.id)" class="btn btn-outline-secondary" type="button">
                                            <i class="bi bi-pencil-square"></i>
                                        </button>
                                    </template>

                                    <template v-else>
                                        <button v-on:click="Save(comercio.id)" class="btn btn-outline-secondary" type="button">
                                            <i class="bi bi-save"></i>
                                        </button>
                                    </template>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Modal-->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cargar Informacion</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div>
                            <label for="formFileLg" class="form-label">
                                Arrastra o da click para cargar un archivo
                            </label>
                            <input @change="SelectedFile($event)" accept=".xlsx,application/vnd.ms-excel" class="form-control form-contro-lg" id="formFileLg" type="file">
                        </div>
                        <div>
                            <a role="button" class="btn btn-link" v-on:click="DownloadFile()">Descargar archivo<i class="bi bi-file-earmark-excel-fill"></i></a>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="#exampleModal">Cerrar</button>
                        <button type="button" v-on:click="UpdateFile()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!--toast-->
        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>


</template>

<!--Vue Script-->
<script>
    import axios from 'axios'
    import { Modal } from 'bootstrap'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "Comercio",
        components: {
            Toast
        },
        data() {
            return {
                comercios: null,
                modifyComercio: null,
                disponibleSelected: 0,
                comercioSave: null,
                precioModel: null,
                Disponibilidad: {
                    0: "Disponible",
                    1: "Apartado",
                    2: "Vendido",
                    3: "Bloqueado"
                },
                modal: null,
                file: "",
                progressUpdate: 50,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                toasts: [],
            }
        },
        methods: {
            getComercios() {
                axios.get('/api/Comercio/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.comercios = response.data;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            DownloadFile() {
                axios({
                    url: '/api/Comercio/get-all/' + this.idProyecto,
                    method: "get",
                    responseType: 'blob',
                    headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") }
                })
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fURL = document.createElement('a');

                        fURL.href = fileURL;
                        fURL.setAttribute('download', 'comercio.xlsx');
                        document.body.appendChild(fURL);

                        fURL.click();
                    })
            },
            Save(id) {
                for (var i = 0; i < this.comercios.length; i++) {
                    if (this.comercios[i].id == id) {
                        this.comercioSave = this.comercios[i];
                    }
                }
                this.comercioSave.disponibilidad = Number(this.disponibleSelected);
                this.comercioSave.precio = Number(this.precioModel);
                


                axios.post('/api/Comercio/' + this.idProyecto, this.comercioSave, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if (response.status == 200 || response.status == 202) {
                            this.modifyComercio = null;
                            this.toasts.push({ "message": "La unidad comercial " + this.departamentoSave.unidad + " se guardo correctamente", "delay": 5000 });
                        }
                        if (response.status == 500) {
                            this.toasts.push({ "message": "La unidad comercial " + this.departamentoSave.unidad + " no se guardo", "delay": 5000 });
                        }
                    });
            },
            Modify(id) {
                for (var i = 0; i < this.comercios.length; i++) {
                    if (this.comercios[i].id == id) {
                        this.disponibleSelected = this.comercios[i].disponibilidad;
                        this.precioModel = this.comercios[i].precio;
                    }
                }
                this.modifyComercio = id;
            },
            ShowModal() {
            },
            SelectedFile(event) {
                this.file = event.target.files[0];
            },
            UpdateFile() {
                var formData = new FormData();
                formData.append("file", this.file);
                axios.post('/api/Comercio/update-all/' + this.idProyecto, formData, {

                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'accept': '*/*',
                        'Authorization': "Bearer " + this.$cookie.getCookie("SID")
                    }
                })
                    .then((response) => {

                        if (response.status == 200) {
                            //document.getElementById('exampleModal').hide();
                            this.modal.hide()
                        }
                    })
                    //.onUploadProgress((progess) => {
                    //    this.progressUpdate = progess;
                    //})
                    .catch(function (error) {
                        alert(error);
                    })
            }
        },
        mounted() {
            if (this.$cookie.getCookie("UserAccess").menu.indexOf("Vivienda") > 0) {
                this.$router.push({ name: "Departamentos" });
            } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Oficina") > 0) {
                this.$router.push({ name: "Oficina" });
            }
            this.getComercios();
            this.modal = new Modal(document.getElementById('exampleModal'));
        },
        emitEventChanged() {
            //this.$emit("IdProyectoChanged", this.idProyecto)
        }
    }

</script>
<style scoped>
    .container{

    }
</style>