<template>
    <div class="container">
        <h1 id="tableLabel">Departamentos</h1>

        <p v-if="!departamentos"><em>Cargando...</em></p>

        <div class="container" v-if="departamentos">
            <div class="row">
                <div>
                    <button @click="modal.show()" class="btn btn-outline-primary float-end" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" href="#exampleModal">
                        Modificar <i class="bi bi-file-earmark-excel"></i>
                    </button>
                </div>
            </div>

            <div class="row" style="margin-top:1rem">
                <div class="container">
                    <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                        <thead>
                            <tr>
                                <th>Unidad</th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Disponibilidad</th>
                                <th>Precio</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="departamento of departamentos" v-bind:key="departamento">
                                <td>{{ departamento.unidad}}</td>
                                <td>{{ departamento.nombre }}</td>
                                <td>{{ departamento.tipo }}</td>

                                <!--disponibilidad-->
                                <template v-if="modifyDepa!=departamento.id">
                                    <td>{{ Disponibilidad[departamento.disponibilidad] }}</td>
                                </template>
                                <template v-else>
                                    <td>
                                        <select v-model="disponibleSelected" class="form-select" aria-label="">
                                            <option value=0>Disponible</option>
                                            <option value=1>Apartado</option>
                                            <option value=2>Vendido</option>
                                            <option value=3>Bloqueado</option>
                                        </select>
                                    </td>
                                </template>

                                <!--precio-->
                                <template v-if="modifyDepa!=departamento.id">
                                    <td>{{departamento.precio}}</td>
                                </template>
                                <template v-else>
                                    <input class="form-control" type="text" :placeholder=departamento.precio v-model="precioModel">
                                </template>

                                <!--boton Modificar/Guardad-->
                                <td>
                                    <template v-if="modifyDepa!=departamento.id">
                                        <button v-on:click="Modify(departamento.id)" class="btn btn-outline-secondary" type="button">
                                            <i class="bi bi-pencil-square"></i>
                                        </button>
                                    </template>

                                    <template v-else>
                                        <button v-on:click="Save(departamento.id)" class="btn btn-outline-secondary" type="button">
                                            <i class="bi bi-save"></i>
                                        </button>
                                    </template>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Modal-->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cargar Informacion</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div>
                            <label for="formFileLg" class="form-label">
                                Arrastra o da click para cargar un archivo
                            </label>
                            <input @change="SelectedFile($event)" accept=".xlsx,application/vnd.ms-excel" class="form-control form-contro-lg" id="formFileLg" type="file">
                        </div>
                        <!--<div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="progressBarstyle" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{progressUpdate}}%</div>
                        </div>-->
                        <div>
                            <a role="button" class="btn btn-link" v-on:click="DownloadFile()">Descargar archivo<i class="bi bi-file-earmark-excel-fill"></i></a>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="#exampleModal">Cerrar</button>
                        <button type="button" v-on:click="UpdateFile()" class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!--toast-->

        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>


</template>

<!--Vue Script-->
<script>
    import axios from 'axios'
    import { Modal } from 'bootstrap'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "Departamentos",
        components: {
            Toast
        },
        data() {
            return {
                departamentos: this.$store.state.Unidades.viviendas,
                modifyDepa: null,
                disponibleSelected: 0,
                departamentoSave: null,
                precioModel: null,
                Disponibilidad: {
                    0: "Disponible",
                    1: "Apartado",
                    2: "Vendido",
                    3: "Bloqueado"
                },
                modal: null,
                toast: null,
                file: "",
                progressUpdate: 50,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                toasts: [],
            }
        },
        props: {

        },
        methods: {
            getDepartamentos() {
                axios.get('/api/Vivienda/Proyecto/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {

                        this.$store.commit('UpdateViviendas', response.data);
                        //this.$store.state.Unidades.viviendas = response.data;
                        this.departamentos = this.$store.state.Unidades.viviendas;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            DownloadFile() {
                axios({
                    url: '/api/Vivienda/get-all/' + this.idProyecto,
                    method: "get",
                    responseType: 'blob',
                    headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") }
                })
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fURL = document.createElement('a');

                        fURL.href = fileURL;
                        fURL.setAttribute('download', 'Vivienda.xlsx');
                        document.body.appendChild(fURL);

                        fURL.click();
                    })
            },
            Save(id) {
                for (var i = 0; i < this.departamentos.length; i++) {
                    if (this.departamentos[i].id == id) {
                        this.departamentoSave = this.departamentos[i];
                    }
                }
                this.departamentoSave.disponibilidad = Number(this.disponibleSelected);
                this.departamentoSave.precio = Number(this.precioModel);



                axios.post('/api/vivienda/' + this.idProyecto, this.departamentoSave, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if (response.status == 200 || response.status == 202) {
                            this.modifyDepa = null;
                            //var data = { "id":1, "message":"Se guardo" };
                            this.toasts.push({ "message": "El departamento " + this.departamentoSave.unidad + " se guardo correctamente", "delay": 5000 });
                        }
                        if (response.status == 500) {
                            this.toasts.push({ "message": "El departamento " + this.departamentoSave.unidad + " no se guardo", "delay": 5000 });
                        }
                    });
            },
            Modify(id) {
                for (var i = 0; i < this.departamentos.length; i++) {
                    if (this.departamentos[i].id == id) {
                        this.disponibleSelected = this.departamentos[i].disponibilidad;
                        this.precioModel = this.departamentos[i].precio;
                    }
                }
                this.modifyDepa = id;
            },
            ShowModal() {
            },
            SelectedFile(event) {
                this.file = event.target.files[0];
            },
            UpdateFile() {
                var formData = new FormData();
                formData.append("file", this.file);
                axios.post('/api/vivienda/' + this.idProyecto, formData, {

                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'accept': '*'
                    }
                })
                    .then((response) => {

                        if (response.status == 200) {
                            //document.getElementById('exampleModal').hide();
                            //this.toasts.push({ "message": "El departamento " + this.departamentoSave.unidad + " se guardo correctamente", "delay": 5000 });
                            this.modal.hide()
                        }
                    })
                    //.onUploadProgress((progess) => {
                    //    this.progressUpdate = progess;
                    //})
                    .catch(function (error) {
                        alert(error);
                    })
            },
            MakeToast() {

                //this.toast = new Toast(document.getElementById('myToastEl'));

                //this.toast.show();
            }
        },
        mounted() {

            if (this.$cookie.getCookie("UserAccess").menu.indexOf("Comercio") > 0) {
                this.$router.push({ name: "Comercio" });
            } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Oficina") > 0) {
                this.$router.push({ name: "Oficina" });
            }
            this.getDepartamentos();
            this.modal = new Modal(document.getElementById('exampleModal'));
            //this.MakeToast()
            //this.viviendas = this.$store.state.departamentos
        },
        computed: {
            progressBarstyle() {
                return {
                    width: "${this.progressUpdate}%"
                }
            }
        }

    }

</script>
<style scoped>
</style>