<template>
    <div class="container">

        <h1>Proyectos</h1>

        <p v-if="!proyectos"><em>Cargando...</em></p>

        <div v-if="proyectos">
            <ul v-for="proyecto of proyectos" v-bind:key="proyecto" class="list-group list-group-flush ">
                <li class="list-group-item">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-light" v-on:click="changeProyecto(proyecto.id)">{{proyecto.nombre}} </button>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</template>


<!--Vue Script-->
<script>
    import axios from 'axios'
    export default {
        name: "Proyectos",
        data() {
            return {
                proyectos: null
            }
        },
        methods: {
            getProyectos() {
                axios.get('/api/Proyectos/'+this.$cookie.getCookie("IDProyecto"), { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.proyectos = response.data;
                    })
                    .catch(function () {
                        this.toasts.push({ "message": "No se pudo acceder a la informacion de proyectos", "delay": 5000 });
                    });
            },
            changeProyecto(id) {
                axios.get("/api/usuario/VerifyAccess/"+ id, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if (response.status != 404) {
                            this.$cookie.setCookie("IDProyecto", response.data.proyectoId);
                            this.$cookie.setCookie("UserAccess", response.data);


                            if (this.$cookie.getCookie("UserAccess").menu.indexOf("Vivienda") > -1) {
                                this.$router.push({ name: "Departamentos" });
                            } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Comercio") > -1) {
                                this.$router.push({ name: "Comercio" });
                            } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Oficina") > -1) {
                                this.$router.push({ name: "Oficina" });
                            }
                        }
                    })

            }
        },
        mounted() {
            this.getProyectos();
        }
    }
</script>

<style scoped>
    li.list-group-item {
        text-align: initial;
    }
</style>