<template>
    <div class="container">
        <h1 id="tableLabel">Citas</h1>

        <p v-if="!citas"><em>Cargando...</em></p>

        <div class="container" v-if="citas">

            <div class="row" style="margin-top:1rem">
                <div class="container">
                    <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Hora</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="cita of citas" v-bind:key="cita">
                                <template v-for="citaClientes in cita.citas" v-bind:key="citaClientes">
                                    <tr>
                                        <td>{{ cita.nombre}}</td>
                                        <td>{{ citaClientes.fecha}}</td>
                                        <td>{{ citaClientes.status }}</td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <!--toast-->

        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "citas",
        components: {
            Toast
        },
        data() {
            return {
                citas: null,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                toasts: [],
            }
        },
        methods: {
            getCitas() {
                axios.get('/api/cliente/cita/proyecto/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {

                        this.citas = response.data;
                    })
                    .catch(() => {
                        this.toasts.push({ "message": "No se pudo acceder a la informacion de citas", "delay": 5000 });
                        //alert(error);
                    });
            }
        },
        mounted() {
            this.getCitas();

        }
    }
</script>