<template>
    <!--<header>
    </header>-->
    <div class="header sticky-top">
        <button class="btn float-start" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
            <i class="bi bi-list fs-1" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>
    .header {
        background: #f53c3c;
        height: 70px;
        max-height: 70px;
    }
</style>