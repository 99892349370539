
<template>
    <div class="container">
        <h1 id="tableLabel">Proyectos</h1>

        <div v-if="proyectos">
            <div class="row" style="margin-top:1rem">
                <div class="container">
                    <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                        <thead>
                            <tr>
                                <th>Nombre del dispositivo</th>
                                <th>Ultima conexión</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="DispositivosPendiente">
                                <td colspan="4">Dispositivos pendiente de Autorizar</td>
                            </tr>
                            <tr v-for="key of proyectos[0].apiKeys" v-bind:key="key" v-bind:value="proyectos.apiKeys">
                                <template v-if="key.isActive==false">
                                    <td>{{key.deviceName}}</td>
                                    <td>{{key.lastLog}}</td>
                                    <td>
                                        <button v-on:click="MakeActive(key.id)" class="btn btn-outline-secondary" type="button"><i class="bi bi-check-lg"></i></button>
                                    </td>
                                    <td>
                                        <button v-on:click="Delete(key.id)" class="btn btn-outline-secondary" type="button"><i class="bi bi-trash"></i></button>
                                    </td>
                                    <!--mapa-->
                                    <!--<td><i class="bi bi-save"></i></td>-->
                                </template>
                            </tr>
                            <tr>
                                <td colspan="4">Dispositivos autorizados</td>
                            </tr>
                            <tr v-for="key of proyectos[0].apiKeys" v-bind:key="key" v-bind:value="proyectos.apiKeys">
                                <template v-if="key.isActive">
                                    <td>{{key.deviceName}}</td>
                                    <td>{{key.lastLog}}</td>
                                    <td>
                                    </td>
                                    <td>
                                        <button v-on:click="Delete(key.id)" class="btn btn-outline-secondary" type="button"><i class="bi bi-trash"></i></button>
                                    </td>
                                    <!--mapa-->
                                    <!--<td><i class="bi bi-save"></i></td>-->
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--toast-->
        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Toast from '@/components/Toast/Toast.vue'
    export default {
        name: "Apikey",
        components: {
            Toast
        },
        data() {
            return {
                proyectos: null,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                toasts: [],
                DispositivosPendiente:false
            }
        },
        methods: {
            Create(proyecto) {
                axios.post('/api/apikey', proyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.proyectos = response.data;
                    });
            },
            GetProyectos() {
                axios.get('/api/apikey/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.proyectos = response.data;
                        this.verifyActiveDevice();
                    });
            },
            MakeToast() {

            },
            MakeActive(id) {

                axios.post('/api/apiKey/AuthorizeApiKey/' + id, "", { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if (response.status == 200) {
                            this.toasts.push({ "message": "El Apikey se autorizo correctamente", "delay": 5000 });

                            for (var i = 0; i < this.proyectos[0].apiKeys.length; i++) {
                                if (this.proyectos[0].apiKeys[i].id == id) {
                                    this.proyectos[0].apiKeys[i].isActive=true;
                                }
                            }
                            this.verifyActiveDevice();
                        }
                    });
            },
            Delete(id) {
                axios.post('/api/ApiKey/DeleteApiKey/' + id, "", { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        if (response.status == 200) {
                            this.toasts.push({ "message": "El Apikey se elimino correctamente", "delay": 5000 });

                            for (var i = 0; i < this.proyectos[0].apiKeys.length; i++) {
                                if (this.proyectos[0].apiKeys[i].id == id) {
                                    this.proyectos[0].apiKeys.splice(i, 1);
                                }
                            }
                            this.verifyActiveDevice();
                        }
                    });
            },
            verifyActiveDevice() {
                this.DispositivosPendiente = false;
                for (var i = 0; i < this.proyectos[0].apiKeys.length; i++) {
                    if (this.proyectos[0].apiKeys[i].isActive == false) {
                        this.DispositivosPendiente = true;
                    }
                }
            }


        },
        mounted() {
            //if (this.$cookie.getCookie("UserAccess").rol.nombre != "volumentoAdmin") {
            //    this.$router.push({ name: "Departamentos" })
            //}
            this.GetProyectos();
        }

    }
</script>