import { createWebHistory, createRouter } from "vue-router";
import FetchData from "@/components/FetchData.vue";
import Departamentos from "@/components/Departamentos.vue";
import Usuario from "@/components/Usuario.vue";
import Login from "@/components/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import Apikey from "@/components/ApiKey.vue";
import Comercio from "@/components/Comercio.vue";
import Oficina from "@/components/Oficina.vue";
import AccountRecovery from "@/components/AccountRecovery.vue";
import RegistroModificacion from "@/components/RegistroModificacion.vue";
import Privacidad from "@/components/Privacidad.vue";
import Proyectos from "@/components/Proyectos.vue";
import Citas from "@/components/Citas.vue";
import notfound from "@/components/404.vue";
import CargarProyecto from "@/components/CargarProyecto.vue";
import DescargaProyecto from "@/components/DescargaProyecto.vue";
import PixelStream from "@/components/PixelStream.vue";

const routes = [
    {
        path: "/FetchData",
        name: "FetchData",
        component: FetchData,
    },
    {
        path: "/Departamentos",
        name: "Departamentos",
        component: Departamentos,
    },
    {
        path: "/Usuario",
        name: "Usuario",
        component: Usuario,
    },
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/Apikey",
        name: "Apikey",
        component: Apikey,
    },
    {
        path: "/Comercio",
        name: "Comercio",
        component: Comercio,
    },
    {
        path: "/Oficina",
        name: "Oficina",
        component: Oficina,
    },
    {
        path: "/Recovery",
        name: "Recovery",
        component: AccountRecovery,
    },
    {
        path: "/Modificaciones",
        name: "Modificaciones",
        component: RegistroModificacion,
    },
    {
        path: "/Privacidad",
        name: "Privacidad",
        component: Privacidad,
    },
    {
        path: "/Proyectos",
        name: "Proyectos",
        component: Proyectos,
    },
    {
        path: "/Citas",
        name: "Citas",
        component: Citas,
    },
    {
        path: "/CargarProyecto",
        name: "CargarProyecto",
        component: CargarProyecto,
    },
    {
        path: "/DescargaProyecto",
        name: "DescargaProyecto",
        component: DescargaProyecto,
    },
    {
        path: "/PixelStream",
        name: "PixelStream",
        component: PixelStream,
    },
    {
        path: "/:catchAll(.*)",
        component: notfound,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;