
<template>
    <div class="container">
        <h1 id="tableLabel">Usuario</h1>
        <div class="mb-3">
            <label class="col-form-label float-left">Nombre</label>
            <input class="form-control" type="text" placeholder="Nombre del Usuario" v-model="usuario.nombre">
        </div>
        <div class="mb-3">
            <label class="col-form-label  float-left">Apellido</label>
            <input class="form-control" type="text" placeholder="Apellido del Usuario" v-model="usuario.apellido">
        </div>
        <div class="mb-3">
            <label class="col-form-label  float-left">Correo</label>
            <input class="form-control" type="text" placeholder="Correo" v-model="usuario.correo">
        </div>
        <div class="mb-3">
            <label class="col-form-label  float-left">Telefono</label>
            <input class="form-control" type="text" placeholder="telefono" v-model="usuario.telefono">
        </div>

        <div class="row" style="margin-top:1rem">
            <div class="container">
                <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                    <thead>
                        <tr>
                            <th>Proyecto</th>
                            <th>Agregar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="proyecto of proyectos" v-bind:key="proyecto">
                            <td>{{ proyecto.nombre}}</td>
                            <td>
                                <input class="form-check-input" type="checkbox" :value="proyecto.id" v-model="proyectosChecked" id="proyecto.id">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mb-3">
            <button v-on:click="Save()" type="submit" class="btn btn-outline-primary float-right">Agregar</button>

        </div>
    </div>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "Usuario",
        data() {
            return {
                usuario: {
                    nombre: "",
                    apellido: "",
                    correo: "",
                    telefono: ""
                },
                proyectos: "",
                proyectosChecked: [],
                proyectosUsuario: [],
                id: ""

            }
        },
        methods: {
            Save() {
                for (var i = 0; i < this.proyectosChecked.length; i++) {
                    this.proyectosUsuario.push({ id: this.proyectosChecked[i] });
                }
                this.usuario.Proyectos = this.proyectosUsuario;

                axios.post('/api/usuario', this.usuario, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then( (response)=> {
                        if (response.status == 202 || response.status == 200) {
                            this.usuario.nombre = "";
                            this.usuario.apellido = "";
                            this.usuario.correo = "";
                            this.usuario.telefono = "";
                            this.proyectosChecked = [];
                        }
                    });
            },
            chechForm: function (e) {
                if (this.usuario.nombre && this.usuario.apellido && this.usuario.correo && this.usuario.telefono) {
                    return true
                }
                e.preventDefault();
            },
            GetProyectos() {
                axios.get('api/proyectos', { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.proyectos = response.data;
                    });
            }

        },
        mounted() {
            //if (this.$cookie.getCookie("UserAccess").rol.nombre != "volumentoAdmin" && this.$cookie.getCookie("UserAccess").rol.nombre != "Administrador") {
            //    this.$router.push({ name: "Departamentos" })
            //}
            this.GetProyectos();
        }

    }
</script>