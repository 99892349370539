
<template>
    <main v-if="!messageGUIDnotFound" class="sesion">
        <form class="position-absolute top-50 start-50 translate-middle" style="width:400px;max-width:450px;">
            <div>
                <input id="email" class="form-control border-0 border-bottom" v-on:blur="VerifyPass" type="password" v-model="pass" placeholder="contraseña" />
                <label v-if="messagePassNotStrong" for="emailRecovery" class="btn-outline-danger">La contraseña debe tener al menos 8 caracteres</label>
            </div>
            <div>
                <input id="emailRecovery" class="form-control border-0 border-bottom" v-on:keyup="VerifyPassEqual" type="password" v-model="confirmPass" placeholder="contraseña" />
                <label v-if="messagePassNotEqual" for="emailRecovery" class="btn-outline-danger">Contraseña no es igual</label>
            </div>
            <button class="w-100 btn btn-lg btn-outline-danger" type="button" id="btnLogin" @click="ChangePass()">Recuperar Contraseña</button>
        </form>
    </main>
    <main v-if="messageGUIDnotFound" class="sesion">
        Informacion de recuperacion de contraseña no encontrada, se redirigira a la pantalla de login en {{timeLeft}}
    </main>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "AccountRecovery",
        data() {
            return {
                GUID: null,
                parameters: null,
                usuario: "",
                messagePassNotEqual: false,
                messagePassNotStrong: false,
                messageGUIDnotFound: false,
                timeLimit: 5,
                timePassed: 0,
                timerInterval: null,
                pass: "",
                confirmPass: ""
            }
        },
        methods: {
            VerifyRecovery() {
                axios.get('/api/usuario/RecoveryGUID/' + this.GUID)
                    .then((response) => {
                        this.usuario = response.data.usuario;
                    })
                    .catch((e) => {
                        if (e.response.status == 404) {
                            this.messageGUIDnotFound = true;
                            this.startTimer();
                        }
                    });
            },
            ChangePass() {
                this.VerifyPass();
                this.VerifyPassEqual();
                if (this.messagePassNotStrong == false && this.messagePassNotEqual == false) {
                    this.usuario.Contrasenia = this.pass;
                    axios.post("/api/usuario/ChangePass", this.usuario).
                        then((reponse) => {
                            if (reponse.status == 200) {
                                this.$router.push({ name: "Login" });
                            }
                        })
                }

            },
            startTimer() {
                this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
            },
            VerifyPass() {
                if (this.pass.length < 8) {
                    this.messagePassNotStrong = true;
                } else {
                    this.messagePassNotStrong = false
                }
            },
            VerifyPassEqual() {
                if (this.pass != this.confirmPass) {
                    this.messagePassNotEqual = true;
                } else {
                    this.messagePassNotEqual = false;
                }
            }
        },
        mounted() {
            this.parameters = this.$route.query
            this.GUID = this.$route.query.GUID
            this.VerifyRecovery();
        },
        computed: {
            timeLeft() {
                return this.timeLimit - this.timePassed
            }
        },
        watch: {
            timeLeft() {
                if (this.timeLimit - this.timePassed <= 0) {
                    this.$router.push({ name: "Login" });
                }
            }
        }

    }
</script>