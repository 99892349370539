<template>
    <div class="container">
        <PieChartChart v-if="state.isLoaded" v-bind:chartData="state.chartData" v-bind:chartOptions="state.chartOptions" />
        <PieChartChart v-if="state.isLoaded" v-bind:chartData="state.chartData" v-bind:chartOptions="state.chartOptions" />
    </div>

</template>

<script>
    import PieChartChart from '@/components/Chart/PieChartChart.vue'
    import axios from "axios"

    export default {
        name: "Dashboard",
        data() {
            return {
                state: {
                    chartData: {
                        //datasets: [
                        //    {
                        //        data: [1,2,3],
                        //        backgroundColor: ['#ffDfff', 'Yellow', 'Blue',"Green"]
                        //    }
                        //],
                        //// These labels appear in the legend and in the tooltips when hovering different arcs
                        //labels: ["1","2","3"]
                    },
                    chartOptions: {
                        responsive: true
                    }
                },
                Disponibilidad: {
                    0: "Disponible",
                    1: "Apartado",
                    2: "Vendido",
                    3: "Bloqueado"
                },
                data: [],
                backgroundColor: [],
                labels: [],
                dataSet: []
            }
        },
        methods: {
            LoadData() {
                axios.get("api/vivienda/Estatus/1", { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        for (var i = 0; i < response.data.length; i++) {
                            this.data.push(response.data[i].count)
                            this.backgroundColor.push("#"+i+"ff0ff")
                            this.labels.push(response.data[i].key.tipo)
                        }
                        this.dataSet.push({ data: this.data, backgroundColor: this.backgroundColor })
                        this.state.chartData = {
                            labels: this.labels,
                            datasets: this.dataSet
                        }
                        this.state.isLoaded = true;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        mounted() {

        },
        beforeMount () {
            this.LoadData()
        },
        components: {
            PieChartChart
        }
    }
</script>

<style>
</style>