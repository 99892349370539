<template>
    <div class="toast fade" role="alert" :id="id" data-bs-autohide="true" data-bs-delay=5000 aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                {{Message}}
            </div>
            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" v-on:click="close()" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
    import { Toast } from 'bootstrap'
    export default {
        name: "Toast",
        data() {
            return {
                id: crypto.randomUUID(),
                toast: null
            }
        },
        props: {
            Message: String,
            delay: Number
        },
        methods: {
            init() {
                //this.toast = new Toast(document.getElementById(this.id));
                new Toast(document.getElementById(this.id)).show();
            },
            close() {
                this.toast = new Toast(document.getElementById(this.id));
                new Toast(document.getElementById(this.id)).hide();
            }

        },
        mounted() {
            //this.id = crypto.randomUUID()
            this.init();
        },
        update() {
        }
    }
</script>