<template>
    <div class="Container">
        <h1 id="tableLabel">Registro de Modificaciones</h1>
        <p v-if="!Modificaciones"><em>Cargando...</em></p>

        <div class="container" v-if="Modificaciones">
            <div class="row" style="margin-top:1rem">
                <div class="container">
                    <table class='table' aria-labelledby="tableLabel" id="table" :fields="fields">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Modulo</th>
                                <th>Descripcion</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Modificacion of Modificaciones" v-bind:key="Modificacion">
                                <td>{{ Modificacion.usuario.nombre}} {{Modificacion.usuario.apellido}}</td>
                                <td>{{ Modificacion.modulo }}</td>
                                <td>{{ Modificacion.accion}} {{Modificacion.datoCambio}} {{Modificacion.modulo}} de {{Modificacion.cambioAnterior}} a {{Modificacion.cambioNuevo}}</td>
                                <td>{{ Modificacion.fecha}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>


<!--Vue Script-->
<script>
    import axios from 'axios'
    export default {
        name: "RegistroModificacion",
        data() {
            return {
                Modificaciones: null,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                accion: {
                    0: "Elimino",
                    1: "Modifico",
                    2: "Agrego"
                }
            }
        },
        methods: {
            getModificaciones() {
                axios.get('/api/modificacion/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.Modificaciones = response.data;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        mounted() {
            this.getModificaciones();
        }

    }
</script>

<!--Style-->

<style scoped>
</style>