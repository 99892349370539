import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/scss/bootstrap.scss'
import "bootstrap"
import { createApp } from 'vue'
import Vidle from 'v-idle-3'
import { createStore } from 'vuex'
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue'
import router from './router'


const store = createStore({
    state() {
        return {
            Unidades: { viviendas: [], Comercios: [], Oficinas: [] },
            pixelstreamInstance: {status:""},
        }
    },
    mutations: {
        updatePixelStreanInstance(state, pixelStreamUpdate) {
            state.pixelstreamInstance = pixelStreamUpdate
        },
        UpdateViviendas(state, vivienda) {
            state.Unidades.viviendas = vivienda;
        },
        UpdateVivienda(state, vivienda) {

            for (let i = 0; i < state.Unidades.viviendas.length; i++) {
                if (state.Unidades.viviendas[i].id == vivienda[0].id) {
                    state.Unidades.viviendas[i] = vivienda[0];
                }
            }
        }
    },
    actions: {
    },
    modules: {
    }
})

const vueApp = createApp(App)
vueApp.use(router)
vueApp.use(VueCookieNext)
vueApp.use(store)
vueApp.use(Vidle)
vueApp.mount('#app')