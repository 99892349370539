<template>
    <div style="position:fixed;top:3px">
    </div>
    <div class="sidebar offcanvas offcanvas-start w-15" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
        <div class="offcanvas-body px-0 nav flex-column">
            <ul class="nav flex-column" style="margin-bottom:auto; flex-direction:column">
                <li class="nav-link h2">
                    {{menuShow.proyecto}}
                    <!--<router-link class="nav-link">Proyecto</router-link>-->
                </li>
                <li>
                    <div class="divider"></div>
                </li>
                
                <li v-if="showDepartamento" class="nav-item">
                    <router-link :to="{ name: 'Departamentos' }" class="nav-link nav-item">Unidades</router-link>

                </li>
                <li v-if="showComercio" class="nav-item">
                    <router-link :to="{ name: 'Comercio' }" class="nav-link nav-item">Comercio</router-link>

                </li>
                <li v-if="showOficina" class="nav-item">
                    <router-link :to="{ name: 'Oficina' }" class="nav-link nav-item">Oficinas</router-link>

                </li>
                <li v-if="showDashboard" class="nav-item">
                    <router-link :to="{ name: 'Dashboard' }" class="nav-link">Dashboard</router-link>
                </li>

                <!--<li v-if="ShowPixelStream" class="nav-item">
                    <router-link :to="{ name: 'PixelStream' }" class="nav-link">PixelStream</router-link>
                </li>-->

                <router-link :to="{ name: 'Citas' }" class="nav-link">Citas</router-link>

            </ul>
            <ul class="nav flex-column">
                <li>
                    <div class="divider"></div>
                </li>

                <li class="nav-link">

                    <div>Cambiar de espacio</div>
                </li>
                <li class="nav-link">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle float-start" style="padding-left:0px" type="button" id="dropdownConfiguracion" data-bs-toggle="dropdown" aria-expanded="false">
                            Configuracion
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownConfiguracion">
                            <li class="nav-item">
                                <router-link :to="{ name: 'Usuario' }" class="nav-link">Agregar Usuario</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Modificaciones' }" class="nav-link">Registro Modificaciones</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Proyectos' }" class="nav-link">Proyectos</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Apikey' }" class="nav-link">Apikey</router-link>
                            </li>
                        </ul>
                    </div>

                </li>

                <li class="nav-link">
                    <a style="text-decoration:none; color:black;" href="https://forms.clickup.com/f/82jb0-1190/YDJ591TBNRSA3FF02J">contacto</a>
                </li>
                <li class="nav-link" @click="closeSession()">
                    Salir
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavMenu",
        data() {
            return {
                isExpanded: false,
                SID: this.session,
                menuShow: "",
                showDepartamento: false,
                showComercio: false,
                showOficina: false,
                showDashboard: false,
                ShowPixelStream: false,
            }
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },
            closeSession() {
                this.$cookie.removeCookie("SID");
                this.$cookie.removeCookie("UserAccess");
                this.SID = this.$cookie.getCookie("SID");
                this.emitEventChanged();
                this.$router.push({ name: "Login" });
            },

            toggle() {
                this.isExpanded = !this.isExpanded;
            },
            emitEventChanged() {
                this.$emit("SIDChanged", this.SID)
            }

        },
        mounted() {
            this.menuShow = this.$cookie.getCookie("UserAccess")

            if (this.menuShow.menu.indexOf("Vivienda") > -1) {
                this.showDepartamento = true;
            }
            if (this.menuShow.menu.indexOf("Comercio") > -1) {
                this.showComercio = true;
            }
            if (this.menuShow.menu.indexOf("Oficina") > -1) {
                this.showOficina = true;
            }
        },
        computed: {

        },
        props: ["session"]
    }
</script>

<style scoped>
    .sidebar {
        margin-top: 70px;
        width: 280px;
        height: 100%;
        position: fixed;
        background: #F1F1F1;
        display: flex;
        padding: 1rem;
        flex-direction: column;
    }

    .nav {
        margin-bottom: 20px;
    }

    .sidebar .nav-link {
        text-align: left;
    }

    .sidebar .nav-link {
        font-weight: 500;
        color: var(--bs-dark);
    }

        .sidebar .nav-link:hover {
            background: var(--bs-light);
            color: var(--bs-primary);
        }

    .divider {
        background: #707070;
        width: 80%;
        height: 1px;
        align-self: center;
    }
</style>