<template>
    <Header v-if="SID"></Header>
    <nav-menu v-if="SID" @SIDChanged="UpdateSID" :session="SID">
    </nav-menu>
    <router-view @SIDChanged="UpdateSID" />
</template>
<script>
    import NavMenu from './components/NavMenu.vue'
    import Header from './components/Header.vue'
    export default {
        name: 'App',
        data() {
            return {
                SID: this.$cookie.getCookie("SID"),
                routeName: this.$router.currentRoute,
                ws: "",
                token: "bearer " + this.$cookie.getCookie("SID"),
                dataJson: "",
            }
        },
        components: {
            Header,
            NavMenu
        },
        methods: {
            verifySession() {
                if (this.$cookie.getCookie("SID") == null && window.location.pathname != "/recovery" && window.location.pathname != "/privacidad") {
                    this.$router.push({ name: "Login" })
                }
                else {
                    this.ws = new WebSocket("wss://" + window.location.host + "/ws?token=" + this.$cookie.getCookie("SID") + "&id=" + this.$cookie.getCookie("IDProyecto"));

                    this.ws.onmessage = (event) => {
                        this.dataJson = JSON.parse(event.data)

                        this.$store.commit('UpdateVivienda', this.dataJson.viviendas);

                        this.$store.commit('updatePixelStreanInstance', this.dataJson.pixelStreamInstances);
                    }
                }
            },
            UpdateSID(data) {
                this.SID = data
            },
            menuChanged(data) {
                this.contextmenu = data
            },
            UpdateIdProyecto(data) {
                this.idProyecto = data
            }
        },
        mounted() {
            this.verifySession();
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        created() {
        }

    }

</script>

<style>
    #app {
        font-family: Px-Grotestk,sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        /*color: #2c3e50;*/
        /*margin-top: 60px;*/
        width: 100%;
        height: 100%
    }

    body {
        display: flex;
        align-items: center;
        height: 100%
    }

    html {
        height: 100%;
    }

    input {
        margin: .3em;
    }
</style>
