<template>
    <div class="container pixelstream">
        <div>
            <p v-if="this.$store.state.pixelstreamInstance.status != 'UNREAL_ENGINE'">Este proceso puede tardar algunos minutos</p>
            <step-progress v-if="this.$store.state.pixelstreamInstance.status != 'UNREAL_ENGINE'" :steps="mySteps" :current-step="currentStep" :active-color="red" icon-class="fa fa-check"></step-progress>
        </div>
        <div v-show="pixelstreamInstance.status =='UNREAL_ENGINE'" v-html=htmlPlayerPixelStream></div>

        <!--<iframe v-show="this.$store.state.pixelstreamInstance.status =='UNREAL_ENGINE'" :src="this.$store.state.pixelstreamInstance.dnsNameVolumento"></iframe>-->

        <v-idle @idle="onidle" @remind="onremind" :duration="300" :reminders="[30]" style="visibility:collapse" />

        <div style="visibility:collapse" id="ipInstance" :value="this.$store.state.pixelstreamInstance.dnsNameVolumento">{{pixelstreamInstance.ip}}</div>
        <div style="visibility:collapse" id="statusunreal" :value="this.$store.state.pixelstreamInstance.status">{{this.$store.state.pixelstreamInstance.status}}</div>
        <!--toast-->
        <div class="toast-container position-fixed p-3 bottom-0 end-0">
            <Toast v-for="toast in toasts" :key="toast.id" :Message="toast.message" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Toast from '@/components/Toast/Toast.vue'
    import StepProgress from 'vue-step-progress';
    import 'vue-step-progress/dist/main.css';
    import htmlPlayerPixelStream from "@/components/PixelStream/Public/player.file.html"
    import webrtcPixelStream from "@/components/PixelStream/scripts/adapter-latest.js"
    //import jsWebplayerPixelStream from "@/components/PixelStream/scripts/webRtcPlayer.file.js"
    import appjsPixelStream from "@/components/PixelStream/scripts/app.file.js"
    import cssPixelStream from "@/components/PixelStream/Public/player.file.css"
    export default {
        name: "PixelStream",
        components: {
            Toast,
            'step-progress': StepProgress,
        },
        data() {
            return {
                htmlPlayerPixelStream,
                appjsPixelStream,
                //jsWebplayerPixelStream,
                cssPixelStream,
                webrtcPixelStream,
                mySteps: ["Servidor Encendido", "Servidor Iniciando", "Servidor Iniciado", "Iniciando Aplicacion"],
                currentStep: 0,
                idProyecto: this.$cookie.getCookie("UserAccess").proyectoId,
                pixelstreamInstance: this.$store.state.pixelstreamInstance,
                toasts: [],
                timer: "",
                isIdle: false,
            }
        },
        methods: {
            onidle() {
                //this.toasts.push({ "message": "La sesion se cerro", "delay": 30 });
                //this.ClosePixelStream();
                this.isIdle = true;
            },
            onremind(time) {

                this.toasts.push({ "message": "La sesion se cerrara si continua con inactividad durante :" + time + " segundos", "delay": time });
                this.isIdle = true;
            },
            fetchEvenList() {
                if (!this.isIdle) {
                    axios.post('/api/pixelstreaminstance/StillAlive/' + this.pixelstreamInstance.instance, this.pixelstreamInstance.instance, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                        //.then((response) => {
                        //    this.pixelstreamInstance = response.data;
                        //})
                        .catch(function (error) {
                            //alert(error.message);
                            this.toasts.push({ "message": error.message, "delay": 5000 });
                        });
                }
            },
            ClosePixelStream() {
                axios.post('/api/pixelstreaminstance/TerminateInstance/' + this.pixelstreamInstance.instance, this.pixelstreamInstance.instance, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                    .then((response) => {
                        this.pixelstreamInstance = response.data;
                    })
                    .catch(function (error) {
                        //alert(error.message);
                        this.toasts.push({ "message": error.message, "delay": 5000 });
                    });
            },
            cancelAutoUpdate() {
                clearInterval(this.timer);
            },
            updateStatePixelStream() {
                switch (this.pixelstreamInstance.status) {
                    case "APAGADO":
                        this.currentStep = 0;
                        break;
                    case "ENCENDIDO":
                        this.currentStep = 1;
                        break;
                    case "INICIANDO":
                        this.currentStep = 2;
                        break;
                    case "INICIADO":
                        this.currentStep = 3;
                        break;
                    case "UNREAL_ENGINE":
                        this.currentStep = 4;
                        break;
                    default:
                }
            }
        },
        mounted() {

            axios.get('/api/pixelstreaminstance/' + this.idProyecto, { headers: { 'Authorization': "Bearer " + this.$cookie.getCookie("SID") } })
                .then((response) => {
                    this.$store.commit('updatePixelStreanInstance', response.data);
                    this.pixelstreamInstance = this.$store.state.pixelstreamInstance;
                    this.updateStatePixelStream();
                    //this.pixelstreamInstance.dnsNameVolumento = "https://" + this.pixelstreamInstance.dnsNameVolumento
                })
            //.catch(function (error) {
            //    //alert(error.message);
            //    this.toasts.push({ "message": error.message, "delay": 5000 });
            //});

            //this.fetchEvenList();
            this.timer = setInterval(this.fetchEvenList, 60000);

            //this.ws = new WebSocket("wss://" + window.location.host + "/ws?token=" + this.$cookie.getCookie("SID") + "&id=" + this.$cookie.getCookie("IDProyecto"));

            //this.ws.onmessage = (event) => {
            //    this.dataJson = JSON.parse(event.data)

            //    this.$store.commit('updatePixelStreanInstance', this.dataJson.pixelStreamInstances);
            //    this.pixelstreamInstance = this.$store.state.pixelstreamInstance;
            //    this.updateStatePixelStream();
            //}

            //let cssPixelStreamScript = document.createElement("link")
            //cssPixelStreamScript.setAttribute("src", this.cssPixelStream)
            //document.head.appendChild(cssPixelStreamScript);

            //let webRtcPlayerJSPixelStream = document.createElement("script")
            //webRtcPlayerJSPixelStream.setAttribute("src", this.jsWebplayerPixelStream)
            //document.head.appendChild(webRtcPlayerJSPixelStream);

            //let webrtcPixelStreamScript = document.createElement("script")
            //webrtcPixelStreamScript.setAttribute("src", this.webrtcPixelStream)
            //document.head.appendChild(webrtcPixelStreamScript);

            //let appJSPixelStreamScript = document.createElement("script")
            //appJSPixelStreamScript.setAttribute("src", this.appjsPixelStream)
            //document.head.appendChild(appJSPixelStreamScript);
        },
        created() {
            //this.pixelstreamInstance = this.$store.state.pixelstreamInstance;

        },
        beforeUnmount() {
            this.cancelAutoUpdate();
        },
    }
</script>
<style scoped>
    .pixelstream {
        /*display: flex;*/
        width: 100%;
        padding: 0px;
    }
</style>