<template>
    
        Algo salió mal. Se te redirigira a la pantalla principal en {{timeLeft}} segundos
    
</template>
<script>
    export default {
        name: "notFound",
        data() {
            return {
                timeLimit: 5,
                timePassed: 0,
                timerInterval: null,
            }
        },
        methods: {
            startTimer() {
                this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
            },
        },
        mounted() {
            this.startTimer();
        },
        computed: {
            timeLeft() {
                return this.timeLimit - this.timePassed
            }
        },
        watch: {
            timeLeft() {
                if (this.timeLimit - this.timePassed <= 0) {
                    this.$router.push({ name: "Login" });
                }
            }
        }
    }

</script>