<template>

    <main class="sesion">
        <form v-if="!AccountRecovery" class="position-absolute top-50 start-50 translate-middle" style="width:400px;max-width:450px;">
            <h1 class="h3 mb-3">Volumento</h1>
            <!--<div>
                <input id="email" class="form-control border-0 border-bottom" type="email" v-model="usuario.correo" placeholder="correo@ejemplo.com" />
                <label v-if="mesaggeValidEmail" for="email" class="btn-outline-danger">Correo electronico no valido</label>
            </div>
            <div>
                <input id="pass" class="form-control border-0 border-bottom" type="password" v-model="usuario.contrasenia" placeholder="Contraseña" />
                <label for="pass">Contraseña</label>
            </div>
            <div class="checkbox mb-3">
                <label><input type="checkbox" value="remember" />Mantener sesion</label>
            </div>
            <button class="w-100 btn btn-lg btn-outline-danger" type="button" id="btnLogin" @click="Login()"> Iniciar sesion</button>
            <label v-if="messageLogin" for="btnLogin" class="text-danger">Usuario o contraseña no validos</label>-->

            <button class="btn btn-link" style="color: #F53C3C" type="button" @click="ShowRecovery()">Recuperar contraseña</button>

        </form>

        <form v-if="AccountRecovery" class="position-absolute top-50 start-50 translate-middle" style="width:400px;max-width:450px;">
            <div>
                <input id="email" class="form-control border-0 border-bottom" type="email" v-model="usuario.correo" placeholder="correo@ejemplo.com" />
            </div>
            <div>
                <input id="emailRecovery" class="form-control border-0 border-bottom" type="email" v-model="correoRecovery" placeholder="correo@ejemplo.com" />
                <label v-if="mesaggeValidEmailRecovery" for="emailRecovery" class="btn-outline-danger">Correo electronico no es igual</label>
            </div>
            <button class="w-100 btn btn-lg btn-outline-danger" type="button" id="btnLogin" @click="Recovery()">Recuperar Contraseña</button>
        </form>
    </main>

    <!--Modal-->
    <div class="alert alert-light fixed-bottom fade show" id="privacidadModal" role="alert">
        <div class=" container-fluid">
            <div class="row">
                <label for="formFileLg" class="form-label fs-6 col-11">
                    REPLIKA CONCEPT WORKS, (en adelante, y de forma conjunta el “Responsable”) con domicilio convencional ubicado en Calle Estatuto jurídico No. 608, Col. Burócratas del Estado, en Monterrey, Nuevo León., Teléfono 8119297788, correo electrónico <a href="mailto:info@volumento.mx" style="color: #F53C3C">info@volumento.mx</a>, le informamos que la finalidad principal para las que recabamos sus datos tienen por objeto LA PRESTACION DE SERVICIOS, Usted podrá consultar nuestro Aviso de Privacidad Integral solicitando el Aviso de Privacidad Integral en la recepción del domicilio indicado, mediante solicitud al correo electrónico indicado, o bien, visitando la página <a href="https://stock.volumento.com/privacidad" style="color: #F53C3C">https://stock.volumento.com/privacidad</a>.
                </label>
                <div class="col-1 ms-auto">
                    <button class="btn btn-lg btn-outline-danger position-absolute top-50 start-80 translate-middle" type="button" data-bs-dismiss="alert">X</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from 'axios'
    export default {
        name: "Login",
        data() {
            return {
                usuario: {
                    correo: "",
                    contrasenia: ""
                },
                SID: "",
                idProyecto: "",
                intentos: 0,
                showForget: false,
                mesaggeValidEmail: false,
                messageLogin: false,
                AccountRecovery: false,
                correoRecovery: "",
                menu: "",
                modal: null,
            }
        },
        methods: {
            Login() {
                if (this.usuario.correo != "" && this.usuario.contrasenia != "") {
                    this.mesaggeValidEmail = false;

                    if (this.validateEmail(this.usuario.correo)) {
                        axios.post("/api/usuario/login", this.usuario)
                            .then((response) => {
                                if (response.status != 404) {
                                    this.$cookie.setCookie("SID", response.data.token);
                                    this.$cookie.setCookie("UserAccess", response.data);
                                    this.$cookie.setCookie("IDProyecto", response.data.proyectoId);
                                    this.idProyecto = response.data.ProyectoId;
                                    this.SID = this.$cookie.getCookie("SID");
                                    this.emitEventChanged();

                                    if (this.$cookie.getCookie("UserAccess").menu.indexOf("Vivienda") > -1) {
                                        this.$router.push({ name: "Departamentos" });
                                    } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Comercio") > -1) {
                                        this.$router.push({ name: "Comercio" });
                                    } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Oficina") > -1) {
                                        this.$router.push({ name: "Oficina" });
                                    }
                                }
                            })
                            .catch((e) => {
                                if (e.response.status == 404) {
                                    this.intentos += 1;
                                    this.messageLogin = true;
                                    if (this.intentos > 1) {
                                        this.showForget = true;
                                    }
                                }
                            });
                    }
                    else {
                        this.mesaggeValidEmail = true
                    }
                }
            },
            verifySession() {
                //if (this.$cookie.getCookie("SID") != null) {

                //    if (this.$cookie.getCookie("UserAccess").menu.indexOf("Vivienda") > -1) {
                //        this.$router.push({ name: "Departamentos" });
                //    } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Comercio") > -1) {
                //        this.$router.push({ name: "Comercio" });
                //    } else if (this.$cookie.getCookie("UserAccess").menu.indexOf("Oficina") > -1) {
                //        this.$router.push({ name: "Oficina" });
                //    }
                //}
            },
            emitEventChanged() {
                this.$emit("SIDChanged", this.SID)
                this.$emit("menuChanged", this.menu)
            },
            validateEmail(email) {
                const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return res.test(String(email).toLowerCase());
            },
            ShowRecovery() {
                this.AccountRecovery = true;
            },
            Recovery() {
                if (this.usuario.correo != "" && this.correoRecovery != "") {
                    if (this.validateEmail(this.usuario.correo) == true && this.validateEmail(this.correoRecovery) == true) {
                        if (this.correoRecovery == this.usuario.correo) {
                            axios.post("/api/usuario/recovery", this.usuario).
                                then((reponse) => {
                                    if (reponse.status == 200) {
                                        this.AccountRecovery = false;
                                    }
                                })
                            
                        }

                    }
                }
            }

        },
        mounted() {
            this.verifySession();
        }
    }
</script>

<style scoped>
    .sesion {
        width: 100%;
        max-width: 430px;
        padding: 1em;
        margin: auto;
        height: 100%;
    }

    /*    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }*/

</style>